import React, { useState } from 'react';
export const UserContext = React.createContext({
  userTokenInfo: null,
  setUserTokenInfo: (state: any) => { },
});

export default (props: any) => {
  const [userTokenInfo, setUserTokenInfo] = useState<any>(null);
  return (
    <UserContext.Provider
      value={{
        userTokenInfo, setUserTokenInfo
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

import React, { useState, useEffect } from "react";
export const NotificationContext = React.createContext({
  hasNoti: false,
  setHasNoti: (state: boolean) => { },
});
export default (props: any) => {
  const [hasNoti, setHasNoti] = useState<boolean>(false);
  return (
    <NotificationContext.Provider
      value={{
        hasNoti,
        setHasNoti
      }}>
      {props.children}
    </NotificationContext.Provider>
  );
};

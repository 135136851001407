import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastAppType } from "../common/constant/enum/toastAppType";
export const ToastAppMsgContext = React.createContext({
  ToastData: { Message: "", Type: ToastAppType.Info }   ,
  setToastData: (state: { Message: string, Type: ToastAppType }) => { },
});
export default (props: any) => {
  const [ToastData, setToastData] = useState<{ Message: string, Type: ToastAppType }>({ Message: "", Type: ToastAppType.Info });
  return (
    <ToastAppMsgContext.Provider
      value={{
        ToastData,
        setToastData
      }}>
      {props.children}
    </ToastAppMsgContext.Provider>
  );
};

import React, { CSSProperties, useState } from 'react';
import { StyledProps } from 'styled-components';
export const LoadingAppContext = React.createContext({
  LoadingStatus: { Ishow: false },
  setLoadingStatus: (state: { Ishow: boolean, Style?: CSSProperties | null }) => { },
  label: "",
  setlabel: (state: string) => { },
} as {
  LoadingStatus: { Ishow: boolean, Style?: CSSProperties | null },
  setLoadingStatus: (state: { Ishow: boolean, Style?: CSSProperties | null }) => void,
  label: string,
  setlabel: (state: string) => void,
});

export default (props: any) => {
  const [LoadingStatus, setLoadingStatus] = useState<{ Ishow: boolean, Style?: CSSProperties | null }>({ Ishow: false, Style: null });
  const [label, setlabel] = useState<string>("");
  return (
    <LoadingAppContext.Provider
      value={{
        LoadingStatus,
        setLoadingStatus,
        label,
        setlabel
      }}
    >
      {props.children}
    </LoadingAppContext.Provider>
  );
};

import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import da from "./locales/da/translation.json"
import en from "./locales/en/translation.json"
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: false,
    react: { 
      useSuspense: false
    },
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: en
      },
      da: {
        translation: da
      },
    },
  })
  .then((r) => r);

export default i18n;  
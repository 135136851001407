export const defaultTheme = {
  // defaultFontStyle: {
  //   fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
  // },
  palette: {
    themePrimary: '#004a62',
    themeLighterAlt: '#d0e5ec',
    themeLighter: '#a8cedb',
    themeLight: '#85b8c9',
    themeTertiary: '#65a3b8',
    themeSecondary: '#498fa6',
    themeDarkAlt: '#317c95',
    themeDark: '#1d6a84',
    themeDarker: '#0d5972',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#ffffff',
    red: 'red',
  },
};

import React from 'react';
import { DialogProvider } from './dialog.context';
import LeftMenuProvider from './leftMenu.context';
import UserContextProvider from './user.context';
import LoadingAppProvider from './loadingApp.context';
import ToastAppMsgProvider from './toastApp.Context';
import NotificationProvider from './notification.context';
const ContainerProvider = (props: any) => {
  return (
    <LoadingAppProvider>
      <UserContextProvider>
        <LeftMenuProvider>
          <DialogProvider>
            <NotificationProvider>
              <ToastAppMsgProvider>{props.children}</ToastAppMsgProvider>
            </NotificationProvider>
          </DialogProvider>
        </LeftMenuProvider>
      </UserContextProvider>
    </LoadingAppProvider>
  );
};
export default ContainerProvider;

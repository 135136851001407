import React, { useState } from 'react';
export const  LeftMenuContext = React.createContext({
  isShow: true,
  setIsShow: (state: boolean) => { },
  selectedMenuItem: "",
  setSelectedMenuItem: (state: string) => { },
});

export default (props: any) => {
  const [isShow, setIsShow] = useState<boolean>(true);
  const [selectedMenuItem, setSelectedMenuItem] = useState<string>("");
  return (
    <LeftMenuContext.Provider
      value={{
        isShow,
         setIsShow,
         selectedMenuItem, 
         setSelectedMenuItem
      }}
    >  
      {props.children}
    </LeftMenuContext.Provider>
  );
};

import React, { useState } from 'react';
export const DialogContext = React.createContext({
  titleDialog: '',
  setTitleDialog: (state: string) => { },
  MsgAlertDialog: '',
  setMsgAlertDialog: (state: string) => { },
  MsgConfirmDialog: '',
  setMsgConfirmDialog: (state: string) => { },
  CallBackConfirmDialog: {action: null as any, param: null},
  setCallBackConfirmDialog: (state: any) => { },
});

export const DialogProvider = (props: any) => {
  const [titleDialog, setTitleDialog] = useState<string>('');
  const [MsgAlertDialog, setMsgAlertDialog] = useState<string>('');
  const [MsgConfirmDialog, setMsgConfirmDialog] = useState<string>('');
  const [CallBackConfirmDialog, setCallBackConfirmDialog] = useState<any>({action: null, param: null});

  return (
    <DialogContext.Provider
      value={{
        MsgAlertDialog,
        setMsgAlertDialog,
        MsgConfirmDialog,
        setMsgConfirmDialog,
        CallBackConfirmDialog,
        setCallBackConfirmDialog,
        titleDialog,
        setTitleDialog
      }}
    >
      {props.children}
    </DialogContext.Provider>
  );
};

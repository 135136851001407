import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
// import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n/i18n';
import { initializeIcons } from 'office-ui-fabric-react';
import ContainerProvider from './context/container.context';
import './css/layout/custom.scss';
import './css/layout/app.scss';
import './css/controll/modal.scss';
import { defaultTheme } from './common/constant/theme';
import { ThemeProvider } from '@fluentui/react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './components/account/login/authAzureConfig';
// @ts-ignore
window['REACT_APP_API_ENDPOINT'] = process.env.REACT_APP_API_ENDPOINT;
initializeIcons('https://static2.sharepointonline.com/files/fabric/assets/icons/');
const App = React.lazy(() => import('./App'));
export const msalInstance = new PublicClientApplication(msalConfig);
ReactDOM.render(
  <ContainerProvider>
    <Suspense fallback={<div className="progress-7"></div>}>
      <section style={{ height: '100%', width: '100%' }}>
        <ThemeProvider style={{ height: '100%' }} applyTo="body" theme={defaultTheme}>
          <App instance={msalInstance} />
        </ThemeProvider>
      </section>
    </Suspense>
  </ContainerProvider>,
  document.getElementById('root'),
);

// if (process.env.NODE_ENV === 'production') {
//   Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DNS });
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
